<template>
  <div class="print-sale-invoice">
    <div class="invoice-header">
      <div class="invoice-header-row">
        <div class="invoice-header-item">
          <b>{{ `${invoice.status.id === 1 ? 'فاکتور رزرو ' : 'فاکتور لغو رزرو'} ${invoice.actionType.title} شماره ${invoice.id.value}` }}</b>
        </div>

        <div class="invoice-header-item">
<!--          <img class="shop-logo" src="https://tennisshop.ir/wp-content/uploads/2021/04/LOGO-TENNIS-SHOP.png"-->
<!--               alt="tennis_shop">-->

          <img class="shop-logo" :src="getClubLogo" alt="tennis_shop">
        </div>

        <div class="invoice-header-item">
          <div><b>تاریخ:</b> <span dir="ltr">{{ invoice.date.value }}</span></div>
        </div>

      </div>
    </div>

    <div class="user-details" v-if="invoice.address">
      <div class="user-details-header">
        <div class="user-details-item">
          <b>نام کامل:</b> {{ `${invoice.address.receiver_name} ${invoice.address.receiver_family}` }}
        </div>

        <div class="user-details-item">
          <b>شماره تماس:</b> {{ invoice.address.receiver_call_number }}
        </div>

        <div class="user-details-item">
          <b>تاریخ سفارش:</b> <span dir="ltr">{{ invoice.date.value }}</span>
        </div>
      </div>

      <div class="user-details-content">

        <div class="user-details-item">
          <b>نشانی:</b> {{ ` ${invoice.address.province}, ${invoice.address.city}, ${invoice.address.address}` }}
        </div>

      </div>
    </div>

    <div class="user-details" v-else-if="invoice.user">
      <div class="user-details-content mt-0">
        <div class="user-details-item">
          <b>نام کامل:</b> {{ ` ${invoice.user.name} ${invoice.user.family}` }}
        </div>

        <div class="user-details-item">
          <b>شماره تماس:</b> {{ invoice.user.phone_number }}
        </div>

        <div class="user-details-item">
          <b>تاریخ سفارش:</b> <span dir="ltr">{{ invoice.date.value }}</span>
        </div>
      </div>
    </div>

    <div class="invoice-content">
      <draggable-dynamic-table v-if="invoice.lines.length"
                               :in-modal="true"
                               :active-grid="true"
                               :fix-screen="false"
                               :options="options"
                               :columns="columnsLabel"
                               :data="invoice.lines"/>
    </div>

    <div class="sales-info-details">
      <div v-if="invoice.actionType.title === 'تلفنی'" class="card-details">
<!--        <div class="card-details-row">-->
<!--          <div class="card-details-title">-->
<!--            <b>شماره کارت</b>-->
<!--          </div>-->
<!--          <div class="card-details-content">-->
<!--            <custom-card-number-input disabled :value="cardNumber" />-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="card-details-row">-->
<!--          <div class="card-details-title">-->
<!--            <b>متعلق به </b>-->
<!--          </div>-->
<!--          <div class="card-details-content">-->
<!--            {{ cardOwner }}-->
<!--          </div>-->
<!--      </div>-->
        <b class="card-detail-title">توضیحات :</b>
        <p class="card-detail-content">
<!--          شماره کارت:-->
<!--          <b>{{ getCardNumber }}</b>-->
<!--          به نام-->
<!--          <b>{{ cardOwner }}</b>-->
        </p>
      </div>
      <div class="price-details">
        <div class="price-details-row">
          <div class="price-details-title">
            <b>مبلغ کل</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.totalPrice)) || 0 }}</span>
            <span>{{ $locale.currency() }}</span>
          </div>
        </div>

        <div class="price-details-row">
          <div class="price-details-title">
            <b>تخفیف</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.totalDiscount)) || 0 }}</span>
            <span>{{ $locale.currency() }}</span>
          </div>
        </div>

        <div v-if="invoice.status.id === 2" class="price-details-row">
          <div class="price-details-title">
            <b>عودت</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.cancelPrice)) || 0 }}</span>
            <span>{{ $locale.currency() }}</span>
          </div>
        </div>

        <div class="price-details-row">
          <div class="price-details-title">
            <b>مبلغ نهایی</b>
          </div>

          <div class="price-details-price">
            <span>{{ addComma(parseInt(invoice.finalPrice)) || 0 }}</span>
            <span>{{ $locale.currency() }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="controller-buttons">
      <button @click="printPage">پرینت</button>

      <router-link :to="{name: 'saleInvoice', params: {id: $route.params.id}}">
        <button>بازگشت</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { addSpace, addComma } from "@/assets/js/functions";
import {getSaleInvoice} from '@/http/requests/booking/sales'
import CustomValidateInput from '@/components/customInput/customValidateInput'
import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
import CustomNumberInput from '@/components/customInput/customNumberInput'
import TimeLine from '@/components/timeline/TimeLine'
import CustomCardNumberInput from "../../../../../components/customInput/customCardNumberInput";

export default {
  name: 'printInvoice',
  metaInfo () {
    return {
      title: this.invoice.actionType && this.invoice.actionType.title && this.invoice.id.value ? `فاکتور رزرو ${this.invoice.actionType.title} شماره ${this.invoice.id.value}` : 'فاکتور رزرو'
    }
  },
  components: {
    CustomCardNumberInput,
    TimeLine,
    CustomNumberInput,
    SuggestBox,
    CustomValidateInput
  },
  data () {
    return {
      options: {
        rowKeyField: 'code'
      },
      columnsLabel: [
        {
          field: 'finalPrice',
          headerName: 'مبلغ',
          width: '150px',
          minWidth: 150,
          type: 'relation',
          relation: '(quantity)[*](unitPrice)',
          relationType: 'price',
          // footer: {
          //   type: 'auto-sum',
          //   textType: 'price'
          // }
        },
        // {
        //   field: 'discount',
        //   headerName: 'تخفیف',
        //   width: '150px',
        //   minWidth: 150,
        // },
        {
          field: 'unitPrice',
          headerName: 'مبلغ واحد',
          width: '110px',
          minWidth: 110,
        },
        {
          field: 'quantity',
          headerName: 'تعداد',
          align: 'center',
          width: '50px',
          minWidth: 50,
          // editable: true,
          // footer: {}
        },
        {
          field: 'name',
          headerName: 'نام',
          align: 'center',
          width: 'calc(50%)',
          minWidth: 200
        },
        // {
        //   field: 'code',
        //   headerName: 'شناسه',
        //   align: 'center',
        //   width: '150px',
        //   minWidth: 150
        // },
        {
          field: 'rowNumber',
          headerName: 'ردیف',
          align: 'center',
          width: '70px',
          minWidth: 70,
          // footer: {}
        }
      ],
      invoice: {
        id: {
          value: '',
          id: 0,
          isValid: true
        },
        status: {
          value: '',
          isValid: true
        },
        user: null,
        address: null,
        date: {
          value: '',
          isValid: true
        },
        actionType: {
          id: 0,
          title: ''
        },
        totalPrice: 0,
        finalPrice: 0,
        totalDiscount: 0,
        cancelPrice: 0,
        lines: []
      },
      invoiceProcesses: [
        {
          label: 'پیش فاکتور فروش',
          value: 1
        },
        {
          label: 'فاکتور فروش',
          value: 2
        },
        {
          label: 'خروج انبار',
          value: 3
        }
      ],
      cardNumber: {
        value: '5022 2910 4884 3088'
      },
      cardOwner: 'آریا احمدیان'
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('auth/setAccessToken')
    }, 50)

    this.getSaleInvoice()
  },

  computed: {
    getClubLogo () {
      return this.$store.state.setting.clubInfo.club_logo
    },
    getCardNumber () {
      return addSpace(this.cardNumber.value)
    }
  },
  methods: {
    getSaleInvoice () {
      getSaleInvoice({id: this.$route.params.id}).then(response => {
        const invoice = response.data.data

        const products = []
        products.push({
          rowNumber: products.length + 1,
          code: invoice.id,
          name: `${invoice.reserved_time.court.name} - ${invoice.reserved_time.time.start_time.substr(0, 5)} تا ${invoice.reserved_time.time.end_time.substr(0, 5)} - ${invoice.reserved_time.date.split(' ')[0]}`,
          quantity: 1,
          unitPrice: {value: invoice.court_price, type: 'price'},
          finalPrice: { value: '', type: 'price' },
          discount: {value: invoice.discount, type: 'price'},
        })

        if (invoice.addons.length > 0) {
          invoice.addons.forEach(addon => {
            products.push({
              id: addon.id,
              rowNumber: products.length + 1,
              name: {
                value:addon.name,
                preContent: {
                  value: addon.required ? '(اجباری)' : '',
                  class: 'text-danger'
                }
              },
              required: addon.required,
              quantity: addon.quantity,
              unitPrice: {value: addon.price, type: 'price'},
              finalPrice: { value: '', type: 'price' }
            })
            this.invoice.totalPrice += addon.price * addon.quantity
          })
        }
        this.invoice.cancelPrice = invoice.cancel_price || 0
        this.invoice.totalDiscount += invoice.discount || 0
        this.invoice.totalPrice += invoice.court_price
        this.invoice.finalPrice = invoice.total_price
        this.invoice.lines = products
        // const row_index = this.columnsLabel.map((e) => {
        //   return e.field
        // }).indexOf('rowNumber')
        // this.columnsLabel[row_index].footer.value = this.invoice.lines.length

        this.invoice.id = {
          value: invoice.invoice_number || invoice.id,
          isValid: true
        }
        this.invoice.status = {
          value: this.getInvoiceStatus(invoice.status),
          id: invoice.status,
          isValid: true
        }
        this.invoice.date = {
          value: invoice.invoice_date,
          isValid: true
        }
        this.invoice.user = invoice.user
        this.invoice.address = invoice.address
        this.invoice.actionType = {id: invoice.action_type}
        switch (invoice.action_type) {
        case 1:
          this.invoice.actionType.title = 'حضوری'
          break
        case 2:
          this.invoice.actionType.title = 'تلفنی'
          break
        case 3:
          this.invoice.actionType.title = 'اینترنتی'
          break
        }
      })
    },
    getInvoiceStatus (id) {
      return this.invoiceProcesses[id - 1].label
    },
    addComma,
    printPage () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
div#app {
  background: #fff;
}

.print-sale-invoice {
  width: 30cm;
  margin: auto;
  background: #fff;
  padding: .5cm;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;

  .invoice-header {
    border: 1px solid #cecece;
    border-radius: .5rem;
    padding: 10px;

    .invoice-header-row {
      display: flex;

      .invoice-header-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        width: 30%;
        font-weight: 500;
        text-align: center;
      }

      div, b {
        flex: 1;
      }

      img {
        object-fit: scale-down;
        height: 100px;
      }
    }
  }

  .user-details {
    margin-top: 10px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    padding: 10px;

    .user-details-header {
      border-bottom: 1px solid #cecece;
      display: flex;
      padding-bottom: 10px;

      .user-details-item {
        flex-grow: 1;
      }
    }

    .user-details-content {
      margin-top: 10px;
      display: flex;

      .user-details-item {
        flex-grow: 1;
      }
    }
  }

  .invoice-content {
    margin-top: 10px;

    .root-header, .root-footer {
      background: #cecece50;
    }
  }

  .sales-info-details {
    display: flex;
    /*align-items: flex-start;*/

    .card-details {
      flex-grow: 1;
      margin-top: 10px;
      padding: 0 10px;
      border: 1px solid #cecece;
      border-radius: .5rem;
      width: 370px;
      margin-left: 0;
      margin-right: 10px;
      text-align: center;
      line-height: 50px;

      .card-detail-title {
        display: block;
        text-align: left;
      }

      .card-detail-content {
        text-align: left;
      }

      .card-details-row {
        display: flex;
        border-bottom: 1px solid #cecece;

        &:last-child {
          border-bottom: 0;
        }

        .card-details-title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          border-right: 1px solid #cecece;
          background: #cecece50;
        }

        .card-details-content {
          line-height: 30px;
          text-align: initial;
          display: flex;
          align-items: center;
          flex-grow: 1;
          padding: 0 10px;
        }


        .card-details-price {
          display: flex;
          flex-grow: 1;

          span:first-child {
            flex: 1;
          }

          span:last-child {
            padding: 0 20px 0 0;
          }
        }
      }
    }
  }

  .price-details {
    margin-top: 10px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    width: 328px;
    margin-left: auto;
    margin-right: 0;
    text-align: center;
    line-height: 50px;

    .price-details-row {
      display: flex;
      border-bottom: 1px solid #cecece;

      &:last-child {
        border: none;
      }

      .price-details-title {
        width: 142px;
        border-right: 1px solid #cecece;
        background: #cecece50;
      }

      .price-details-price {
        display: flex;
        flex-grow: 1;

        span:first-child {
          flex: 1;
        }

        span:last-child {
          padding: 0 20px 0 0;
        }
      }
    }
  }

  .controller-buttons {
    display: flex;
    justify-content: center;

    button {
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      line-height: 40px;
      background: #cecece50;
      border: 1px solid #cecece;
      border-radius: .5rem;
      min-width: 120px;
      transition: all .3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: #cecece90;
      }
    }
  }

  table {
    border: 1px solid #1b1b1c;
    border-radius: 5px;

    tr {

      div.select-suggest-list {
        right: 0 !important;
        left: 0 !important;
      }
    }
  }

  .draggable-dynamic-table {
    .root-body {
      .root-body-cell {
        white-space: unset !important;
        /*line-height: 40px;*/
      }
    }
  }
}

@media print {
  .print-sale-invoice {
    font-size: 14pt;
    line-height: 45px;
    padding: 0;

    b {
      color: #000000ca;
    }

    .draggable-dynamic-table {

      .root-header-cell {
        font-weight: bold;
        color: #000000ca;
      }

      .root-body {
        font-weight: 450;
        font-size: 14pt;
      }
    }

    .price-details {
      break-inside: avoid-page;
    }

    .controller-buttons {
      display: none;
    }
  }

  @page {
    //size: landscape;
    margin: 5mm;
  }
}
</style>
